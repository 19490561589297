import React from 'react';
import logo from '../assets/images/logo.png';
function Myfooter() {
  return (
    <footer className="App_footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="footer_logo">
              <img src={logo} alt="Logo"></img>
            </div>
            <p>© Copyright 2024, AngelPoop</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Myfooter;
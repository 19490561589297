import React, { useEffect } from 'react';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import maininnervideo from '../assets/videos/Comp4.webm';
import mainvideo from '../assets/videos/Comp5.webm';
import aboutvideo1 from '../assets/videos/Comp3.webm';
import aboutvideo2 from '../assets/videos/Comp2.webm';
import tokenomicback from '../assets/images/topbanner.webp';
// import maintopbackground from '../assets/images/space_bg.webp';
import Iconcopy from '../assets/images/Iconcopy.png';
import notex from '../assets/images/1.webp';
import contractrenounced from '../assets/images/2-2.webp';
import liquiditylocked from '../assets/images/3-3.webp';
import supply from '../assets/images/4.webp';

import computer from '../assets/images/computer.webp';
import spave from '../assets/images/spave.webp';
import flight from '../assets/images/flight.webp';
import superman from '../assets/images/superman.webp';

import howbuy from '../assets/videos/howbuy.webm';

function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="main_home_page_css">
      <div className='main_slider_top' >
        <video width="100%" className='backgroundvideo' autoPlay muted loop>
          <source src={mainvideo} type="video/webm" />
        </video>
        <video className='innervideo' autoPlay muted loop>
          <source src={maininnervideo} type="video/webm" />
        </video>
      </div>
      <div className='section_overlay_about'>
        <div className='container'>
          <h2>Who’s Angel Poop ?</h2>
          <p>Angelpoop is a mystical fusion of divine intervention and digestive matter born in a secluded lavatory, rumored to be enchanted by ancient spirits.
          Despite its celestial origins, Angelpoop remains a humble and grounded memecoin, never seeking praise or adulation for its benevolent deeds.</p>
          <p>Together, lets make sure that the Legend of Angelpoop will be etched into the annals of history as a beacon of light in a world shrouded in darkness.</p>
        </div>
      </div>
      <div className='about_block'>
        <video width="100%" className='aboutvideo' autoPlay muted loop>
          <source src={aboutvideo1} type="video/webm" />
        </video>
        <video width="100%" className='innerabout' autoPlay muted loop>
          <source src={aboutvideo2} type="video/webm" />
        </video>
      </div>
      <div className='section_overlay_csscroll'>
        <marquee><img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop </marquee>
      </div>
      <div className='tokefeatures' style={{ backgroundImage: `url(${tokenomicback})` }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='heading_tag'>
                <h2>Pooponomics</h2>
              </div>
            </div>
            <div className='col-md-5 col-sm-6'>
              <ul>
                <li>
                  <img src={notex}/>
                  <span>No tax</span>
                </li>
                <li>
                  <img src={contractrenounced}/>
                  <span>Contract renounced</span>
                </li>
                <li>
                  <img src={liquiditylocked}/>
                  <span>Liquidity locked</span>
                </li>
                <li>
                  <img src={supply}/>
                  <span>Supply 100B</span>
                </li>
              </ul>
            </div>
            <div className='col-md-7 col-sm-6'>
              <div className='tokefeaturesvideoblock'>
                <video className='innervideo' autoPlay muted loop>
                  <source src={howbuy} type="video/webm" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='inner_overlay_animation'>
        <div className='container'>
           <div className='row'>
              <div className='col-md-12'>
                <div className='inner_data_wallet'>
                  <span>0x013b6451E2B2131052D3473593bD786df5143fAF <i class="fa fa-copy"></i></span>
                </div>
              </div>
           </div>
          <h3>How to Buy</h3>
          <div className='row mainrowcenter'>
            <div className='col-md-5'>
              <div className='inner_data'>
                <img src={computer}/>
                <h4>Add Base to Metamask</h4>
                <p>Navigate to the 'Settings' option within your wallet and locate the networks section. Manually include a new network, and input the following details for Base</p>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='inner_data'>
                <img src={spave}/>
                <h4>Load up ETH</h4>
                <p>Your wallet to the Ethereum blockchain. Select the amount of ETH you wish to bridge from the ERC20 network to Base.</p>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='inner_data'>
                <img src={flight}/>
                <h4>Buy $AngelPoop</h4>
                <p>Go to Uniswap and import the contract address for $AngelPoop (Located at the top of the website) to exchange your ETH.</p>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='inner_data'>
                <img src={superman}/>
                <h4>Add to Wallet</h4>
                <p>Simply include the $AngelPoop contract address in your Metamask Wallet to display your $AngelPoop tokens. Haaan!</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='walletid'></div>
            </div>
          </div>
        </div>
      </div>
      <div className='section_overlay_csscroll section_overlay_csscroll2'>
        <marquee><img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop <img src={Iconcopy}/> AngelPoop </marquee>
      </div>
    </section>
  );
}
export default Homepage;
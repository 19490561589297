import {React} from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/main.css";
import logo from '../assets/images/logo.png';
// import telegram from '../assets/images/telegram.svg';
// import facebook from '../assets/images/facebook.svg';
// import instagram from '../assets/images/instagram.svg';
// import twitter from '../assets/images/twitter.svg';

const Myheader = () => {

  return (
    <header className="App_header">
      <div className="container">
        <div className="menu_bar">
          <div className="main_logo">
            <a href="/"><img src={logo} alt="Logo"></img></a>
          </div>
          <div className="social_menu_links">
            <ul>
              {/* <li><a href="#"><img src={telegram} alt="telegram"></img></a></li> */}
              {/* <li><a href="#"><img src={facebook} alt="facebook"></img></a></li>
              <li><a href="#"><img src={instagram} alt="instagram"></img></a></li> */}
              {/* <li><a href="#"><img src={twitter} alt="twitter"></img></a></li> */}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Myheader;